import request from '@/utils/request'

export const getUserInfo = (data: any) =>
  request({
    url: '/users/info',
    method: 'post',
    data
  })

// export const login = (data: any) =>
  // request({
  //   url: '/users/login',
  //   method: 'post',
  //   data
  // })

export const logout = () =>
  request({
    url: '/users/logout',
    method: 'post'
  })

export const login = (data: any) =>
  request({
    url: `v1/login/${data.code}`,
    method: 'post'
  })


export const face = (data: any) =>
  request({
    url: `v2/face`,
    method: 'post',
    data
})

export const faced = (data: any) =>
  request({
    url: `/v2/faced`,
    method: 'get',
    params: data
})

export const certInfo = () =>
  request({
    url: `v2/musician/cert`,
    method: 'get'
})

export const question = (data: any) =>
  request({
    url: `v2/quest`,
    method: 'post',
    data
})

export const getQuestion = () =>
  request({
    url: `/v2/quest/1`,
    method: 'get'
})