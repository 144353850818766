import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import { UserModule } from '@/store/modules/user'
import { getToken, setToken, removeToken } from '@/utils/cookies'
import router from '@/router'
import Vue from 'vue'
// (Vue as any).$axios = axios

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 120000
})

// Request interceptors
service.interceptors.request.use(
  (config) => {
    // Add X-Access-Token header to every request, you can add other custom headers here
    if (getToken()) {
      config.headers.Authorization = `Bearer ${getToken()}`;
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  (response) => {
    const res = response as any
    // console.log(res.code !== 0)
    if (res.data.code !== 0) {
      if(res.data.code === 10002 || res.data.code === 500 || res.data.code === 10000) {
        Message({
          message: 'token失效，请重新登录',
          type: 'error',
          duration: 5 * 1000
        })
        removeToken();
        router.push(`/login?redirect${(router as any).history.current.path}`)
      }else{
        Message({
          message: res.error || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject(new Error(res.error || 'Error'))
    } else {
      return response.data
    }
  },
  (error) => {
    const res = error.response
    console.log(res)
    if(res.data.code === 10002 || res.data.code === 500 || res.data.code === 10000) {
      Message({
        message: 'token失效，请重新登录',
        type: 'error',
        duration: 5 * 1000
      })
      removeToken();
      router.push(`/login?redirect${(router as any).history.current.path}`)
    }else{
      console.log(0)
      console.log(res.data.error)
      if(res.data.error === '参数非法(certName)'){
        res.data.error = "信息格式错误或者不匹配"
      }
      Message({
        message: res.data.error || error.message,
        type: 'error',
        duration: 5 * 1000
      })
    }
    
    return Promise.reject(error)
  }
)
// function checkMsg(msg: string):string {
//   console.log(1)
//   const map:any = {
//     "参数非法(certNo)": "证件号码格式错误"
//   }
//   // console.log(map.has(msg), map[msg], msg)
//   // console.log(map.has(msg) ? map[msg] : msg)
//   // return map[msg] || ''
//   return map.msg || msg
// }
export default service
